<template>
  <div class="banner_com_wrap" :style="{ '--width': width, '--height': height, '--radius': `${radius}px` }">
    <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerArr" :key="index">
        <img class="imgs" :src="`${item.picture}`" alt="" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Toast, Swipe, SwipeItem } from 'vant';
export default {
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "calc(150rem / 16)"
    },
    radius: {
      type: Number,
      default: 4
    },
    autoplay: {
      type: Number,
      default: 6000
    },
    position: {
      type: String,
      default: "home"
    }
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data() {
    return {
      bannerArr: [],
      loadingNum: 0
    }
  },
  created() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      Toast.loading();
      this.$api.getBanner(
        (res) => {
          Toast.clear();
          if (res.length) {
            this.bannerArr = res;
            this.islocalImg = false;
          }
        },
        (err) => {
          Toast.clear();
        },
        this.position
      );
    },
  },
}
</script>

<style lang="less" scoped>
.banner_com_wrap {
  width: var(--width, 100%);

  .my-swipe .van-swipe-item {
    width: var(--width, 100%);
    color: #fff;
    font-size: 20px;
    min-height: calc(150rem / 160);
    height: var(--height, calc(150rem / 16));
    text-align: center;
    background-color: transparent;
    border-radius: var(--radius, 4px);

    .imgs {
      width: 100%;
      height: 100%;
      border-radius: var(--radius, 4px);
    }
  }
}
</style>