<template>
  <div class="wrap" :style="{ minHeight: fullScreen ? '' : 0 }">
    <div class="tabs fixed" v-if="tabs">
      <div
        class="tabs_wrap flex around bg-fff"
        :style="{
          borderTop: tabTopBorder ? '' : 0,
          borderBottom: tabBottomBorder ? '' : 0,
          '--active': active || '',
          '--inActive': inActive || '',
        }"
      >
        <div
          class="tab_item flex flex-center relative"
          v-for="(item, index) in tabs"
          :key="index"
          @click="tabChange(item)"
        >
          <div
            :style="{
              color: tabVal
                ? tabNow === item[tabVal]
                  ? 'var(--active)'
                  : 'var(--inActive)'
                : tabKey
                ? tabNow === item[tabKey]
                  ? 'var(--active)'
                  : 'var(--inActive)'
                : tabNow === item
                ? 'var(--active)'
                : 'var(--inActive)',
            }"
            v-if="item"
          >
            {{ tabKey ? item[tabKey] : item }}
          </div>
          <div
            class="absolute"
            :class="tabNow === item[tabVal] ? 'line':''"
            v-if="item"
            :style="{
              backgroundColor: tabVal
                ? tabNow === item[tabVal]
                  ? 'var(--active)'
                  : ''
                : tabKey
                ? tabNow === item[tabKey]
                  ? 'var(--active)'
                  : ''
                : tabNow === item
                ? 'var(--active)'
                : '',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="placeholder" v-if="tabs && tabFixed"></div>
    <div class="list" v-if="list">
      <van-list
        @load="nextPage"
        v-model="loading"
        :finished="finish"
        :finished-text="$t('Hint.notMore')"
        :loading-text="$t('Hint.loading')"
      >
        <div v-for="(item, index) in listData" :key="index">
          <div
            class="padding0-12 top18"
            v-if="listData && listData.length && !customList"
          >
            <div class="radius10 bg-fff">
              <slot name="listItem" :data="item"></slot>
              <slot :name="tabNow" :data="item"></slot>
            </div>
          </div>
          <!--  -->
          <div v-if="listData && listData.length && customList">
            <slot name="listItem" :data="item"></slot>
            <slot :name="tabNow" :data="item"></slot>
          </div>
        </div>
        <div class="nomore" v-if="finish && listData.length">
          <!-- <u-loadmore status="nomore" :nomoreText="$t('noMore')" /> -->
          <!-- {{ $t("noMore") }} -->
        </div>
        <div class="nodata" v-if="finish && !listData.length">
          <van-image :src="verConfig.nullPng || nullImg" mode="aspectFit" style="width: 100vw" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import noDataBack from "@tt/assets/Img/null/null.png";
import { GET_LIST_COM } from "@tt/api";
import { List, Toast, Image } from "vant";
export default {
  components: {
    [List.name]: List,
    [Image.name]: Image,
  },
  props: {
    url: {
      type: String,
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
    tabFixed: {
      type: Boolean,
      default: true,
    },
    //   tab的属性
    tabs: {
      type: Array,
    },
    tabKey: {
      type: String,
    },
    tabVal: {
      type: String,
    },
    active: {
      type: String,
    },
    inActive: {
      type: String,
    },
    tabTopBorder: {
      type: Boolean,
      default: true,
    },
    tabBottomBorder: {
      type: Boolean,
    },
    paramKey: {
      type: String,
      default: "",
    },
    initParamData: {
      type: Object,
    },
    // list的属性
    list: {
      type: Boolean,
    },
    perPage: {
      type: Number,
      default: 8,
    },
    request: {
      type: Boolean,
      default: true,
    },
    customList: {
      type: Boolean,
    },
    refresh: {
      type: Number,
      default: 0,
    },
    dataKey: {
      type: String,
      default: "lists",
    },
    nullImg: {
      type: String,
      default: noDataBack
    }
  },
  data() {
    return {
      tabNow: "",
      listData: [],
      currentPage: 1,
      params: {},
      finish: false,
      loading: false,
      firstGetList: true,
      tempTab: "",
    };
  },
  created() {
    this.tabNowInit();
    if (this.request) {
      this.getList();
    }
  },
  methods: {
    tabChange(item) {
      if (this.tabVal) {
        if (item[this.tabVal] !== this.tabNow) {
          this.tabNow = item[this.tabVal];
          this.listData = [];
          this.finish = false;
        }
      } else if (this.tabKey) {
        if (item[this.tabKey] !== this.tabNow) {
          this.tabNow = item.name;
        }
      } else {
        if (item !== this.tabNow) {
          this.tabNow = item;
        }
      }
      this.currentPage = 1;
      this.tempTab = new Date().getTime() + Math.round(Math.random() * 1000);
      this.$emit("tabChange", item);
      if (this.request) {
        this.getList();
      }
    },
    tabNowInit() {
      if (this.tabs && this.tabs.length) {
        if (this.tabVal) {
          this.tabNow = this.tabs[0][this.tabVal];
        } else if (this.tabKey) {
          this.tabNow = this.tabs[0][this.tabKey];
        } else {
          this.tabNow = this.tabs[0];
        }
      }
    },
    nextPage() {
      if (!this.finish) {
        this.currentPage += 1;
        this.getList((res) => {
          if (!res.lists) {
            this.currentPage -= 1;
          }
        });
      }
    },
    initParams() {
      this.params[this.paramKey] = this.tabNow;
      this.params.perPage = this.perPage;
      this.params.page = this.currentPage;
      if (this.initParamData) {
        this.params = Object.assign(this.params, this.initParamData);
      }
    },
    async getList(success) {
      if (!this.request) {
        this.finish = true;
        return;
      }
      const temp = this.tempTab;
      this.loading = true;
      this.initParams();
      try {
        const { currentPage, hasMorePages, lists } = await GET_LIST_COM(
          this.url,
          this.params
        );
        this.loading = false;
        !!success && success({ lists });
        !hasMorePages && (this.finish = true);
        if (this.firstGetList) {
          this.listData = lists;
          this.firstGetList = false;
          return;
        }
        // if(temp !== this.tempTab) return
        if (this.currentPage == 1) {
          this.listData = lists;
        } else {
          this.listData = [...this.listData, ...lists];
        }
      } catch (error) {
        Toast(error);
        this.loading = false;
      }
    },
    handleRefresh(){
      this.currentPage = 1;
      if(!this.request) return;
      this.getList();
    }
  },
  watch: {
    tabs() {
      this.tabNowInit();
    },
    refresh(val) {
      if (val) {
        this.handleRefresh();
      }
    },
  },
};
</script>

<style scoped>
@import url("./common.css");

.wrap {
  background-color: var(--bg);
  min-height: calc(100vh - calc(44rem / 32));
}

.tabs {
  --active: var(--theme);
  --inActive: #707080;
  width: 100vw;
  z-index: 8;
}

.placeholder {
  width: 100vw;
  height: calc(61rem / 32);
}

.tabs_wrap {
  border-top: 1px solid var(--bg);
  border-bottom: 1px solid var(--bg);
}

.tab_item {
  height: calc(61rem / 32);
  background-color: #fff;
  font-size: calc(24rem / 32);
}

.line {
  height: calc(4rem / 32);
  width: calc(80rem / 32);
  bottom: 0;
  border-radius: calc(99rem / 32);
}

.nomore {
  color: #707080;
  height: calc(60rem / 32);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nodata {
  width: 100vw;
  height: calc(100vh - calc(44rem / 32));
  background-color: #fff;
}
</style>