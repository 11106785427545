<template>
  <span>{{ $t(label, options) }}</span>
</template>

<script>
export default{
  props: {
    label: String,
    options: {
      type: Object,
      default: ()=> ({})
    }
  }
}
</script>

<style lang="less" scoped></style>