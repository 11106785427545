<template>
  <div>
    <VanImage :width="size" :height="size" :src="$img(src)" :round="round" v-if="round">
      <VanImage slot="error" :width="size" :height="size" :src="defaultSrc"
        :round="round" />
    </VanImage>
    <VanImage :width="size" :height="size" :src="$img(src)" :radius="radius" v-else>
      <VanImage slot="error" :width="size" :height="size" :src="defaultSrc"
        :radius="radius" />
    </VanImage>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
import defaultImg from "@tt/assets/Img/myImg/MyOne.png"
export default {
  props: {
    size: {
      type: Number | String,
      default: 60,
    },
    src: {
      type: String,
      default: ""
    },
    round: {
      type: Boolean,
      default: true,
    },
    radius: {
      type: Number | String,
      default: 0,
    },
    defaultSrc: {
      type: String,
      default: defaultImg
    }
  },
  components: {
    VanImage,
  }
};
</script>

<style lang="less" scoped></style>