<script>
import {Step, Steps, Tab, Tabs} from "vant";
import List from "./List.vue";

export default {
  components: {Tab, Steps, Tabs, List, Step},
  props: {
    hasNav: Boolean
  },
  data: () => ({
    tabs: [
      {
        label: 'newAdd.all',
        value: 0
      },
      {
        label: 'newAdd.Income',
        value: 1,
      },
      {
        label: 'newAdd.Expenditure',
        value: 2,
      }
    ],
  }),
  created() {
  },
  methods: {},
}
</script>

<template>
  <div>
    <Tabs sticky :offset-top="hasNav ? 46 : 0">
      <Tab v-for="(t_item,index) in tabs" :key="index" :title="$t(t_item.label)">
        <List url="/users/account/lists" :initParams="{setup: t_item.value, type: 'money', subtype: 0}" :ref="`list_${index}`">
          <template #item="{data:items}">
            <div class="tabs-item">
              <div class="list-item">
                <div class="list-item-left">
                  <p class="list-item-title">{{ items.title }}</p>
                  <span class="list-item-indate">{{
                      $format(items.indate)
                    }}</span>
                </div>
                <div class="list-item-right">
              <span v-if="items.setup <= 0" class="list-item-money">{{
                  items.setup
                }}</span>
                  <span v-else class="list-item-money-green"
                  >+{{ items.setup }}</span
                  >
                  <!-- language-ch 余额 -->
                  <span class="list-item-after"
                  >{{ $t('newAdd.Balance') }}：{{ items.after }}</span
                  >
                </div>
              </div>
            </div>
          </template>
        </List>
      </Tab>
    </Tabs>
  </div>
</template>

<style scoped lang="less">
@import "~@tt/assets/cssLib/lib.less";
.list-item {
  width: 100%;
  padding: calc(10rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: calc(1rem / 16);
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
  box-sizing: border-box;
  background: #fff;
  &-left{
    flex: 1;
  }
  &-right{
    .flex(@ver: flex-end);
  }
  &-title{
    flex: 1;
    font-size: calc(13rem / 16);
    color: #242424;
  }
  &-indate{
    margin-top: calc(6rem / 16);
    font-size: calc(12rem / 16);
    color: #888888;
  }
  &-money{
    padding-left: calc(6rem / 16);
    font-size: calc(17rem / 16);
    color: #dc1323;
    &-green{
      color: #1bb019;
    }
  }
  &-after {
    margin-top: calc(3rem / 16);
    font-size: calc(11rem / 16);
    line-height: calc(17rem / 16);
    padding-left: calc(6rem / 16);
    color: #555555;
  }
}
//.list-item-right {
//  display: flex;
//  align-items: flex-end;
//}
//.list-item-title {
//  flex: 1;
//  // height: calc(16rem / 16);
//  font-size: calc(13rem / 16);
//  color: #242424;
//}
//.list-item-indate {
//  margin-top: calc(6rem / 16);
//  font-size: calc(12rem / 16);
//  color: #888888;
//}
//.list-item-money,
//.list-item-money-green {
//  padding-left: calc(6rem / 16);
//  font-size: calc(17rem / 16);
//  color: #dc1323;
//}
//.list-item-money-green {
//  color: #1bb019;
//}
//.list-item-after {
//  margin-top: calc(3rem / 16);
//  font-size: calc(11rem / 16);
//  line-height: calc(17rem / 16);
//  padding-left: calc(6rem / 16);
//  color: #555555;
//}
</style>