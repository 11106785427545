<template>
  <div class="progress" :style="{
    '--color': activeColor,
    '--inActiveColor': inActivecolor,
    '--activeValue': activeValue,
    '--height': height
  }">
  </div>
</template>

<script>
export default {
  props: {
    cur: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    activeColor: {
      type: String,
      default: '#f00'
    },
    inActivecolor: {
      type: String,
      default: '#f5f5f5'
    },
    height: {
      type: String,
      default: '12px'
    }
  },
  computed: {
    activeValue() {
      return Math.round(this.cur / this.max * 100) / 100;
    }
  }
}
</script>

<style lang="less" scoped>
.progress {
  width: 100%;
  position: relative;
  border-radius: calc(var(--height) / 2);
  height: var(--height);
  overflow: hidden;
  background-color: var(--inActiveColor);

  &::before {
    content: "";
    position: relative;
    display: block;
    background-color: var(--color);
    width: calc(100% * var(--activeValue));
    max-width: 100%;
    height: var(--height);
    border-radius: calc(var(--height) / 2);
  }
}
</style>